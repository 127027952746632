import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import Modal from '../components/atom/Modal'
import moment from 'moment'
import Dashboard from '../components/Dashboard'
import SearchInput from '../components/atom/SearchInput'
import StreetRatesTable from '../components/StreetRatesTable'
import Pagination from '../components/atom/Pagination'
import apiClient from '../utils/apiClient'
import portfolioService from '../services/portfolio';
import { AuthContext } from '../context/authContext';
import { set } from 'react-hook-form';

const PAGE_SIZE = 10

const StreetRates = () => {
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [debouncedTerm, setDebouncedTerm] = useState(search)
  const [updatedFacilitiesCount, setUpdatedFacilitiesCount] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [publishIndividualFacilityId, setPublishIndividualFacilityId] = useState(null);
  const [latestPublishedDate, setLatestPublishedDate] = useState('')
  const [sort, setSort] = useState('facility_name')
  const [orderby, setOrderby] = useState('asc')
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [portfolioSettings, setPortfolioSettings] = useState()
  const [errorLog, setErrorLog] = useState('');
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, auth } = useContext(AuthContext);
  const portfolio_id = auth?.user?.portfolio_id;

  const fetchData = async () => {
    try {
      setLoading(true)
      let api = `/street_rates`

      let queries = []
      if (search) queries.push(`search=${search}`)
      queries.push(`status=enabled`)
      if (sort) queries.push(`sort=${sort}`)
      if (orderby) queries.push(`orderby=${orderby}`)
      if (currentPage) queries.push(`page=${currentPage}`)
      queries.push(`limit=${PAGE_SIZE}`)
      if (queries.length) api += '?' + queries.join('&')

      let response = await apiClient.get(api)
      const { result, pagination } = await response.data
      setData(result)
      setPagination(pagination)
      await getPortfolioSettings()
    } catch (error) {
      console.error('Error fetching data: ', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(search)
      setCurrentPage(1)
    }, 500)

    return () => {
      clearTimeout(timerId)
    }
  }, [search])

  useEffect(() => {
    fetchData()
  }, [debouncedTerm, sort, orderby, currentPage])

  const handleChange = (e) => {
    const value = e.target.value
    setSearch(value)
  }

  const handlePagination = (page) => {
    setCurrentPage(page)
    setPagination(pagination => ({...pagination, page: page}))
  }

  const handleFacilitiesChanged = (numOfUpdatedFacilities) => {
    setUpdatedFacilitiesCount(numOfUpdatedFacilities)
  }

  const handlePublishNewRates = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setPublishIndividualFacilityId(null);
  }

  const closeModalError = () => {
    setModalErrorIsOpen(false);
    setErrorLog('');
  }

  const confirmPublish = () => {
    setIsProcessing(true);
    apiClient.post('/street_rates/submit').then(function (response) {
      setLatestPublishedDate(moment(new Date()).format('MM/DD/YYYY, hh:mm A'));
      setModalIsOpen(false);

      setTimeout(() => {
        setIsProcessing(false);
        if (window.confirm('Rates successfully published')) {
          setUpdatedFacilitiesCount(0);
          fetchData();
        }
      }, 300);
    }).catch(function (error) {
      setIsProcessing(false);
      setModalIsOpen(false);

      let message = '';
      let errorLogs = error?.response?.data?.errors || [];

      if (errorLogs.length > 0) {
        message = errorLogs.slice(0, 3).map((log) => `<p class="mb-2">${log}</p>`).join('');
      }

      if (errorLogs.length > 3) {
         message = message + "<p>...</p>"
      }
      setErrorLog(message);
      setModalErrorIsOpen(true);
    })
  }

  const handleUpdate = async (facility) => {
    try {
      await apiClient.post('/street_rates/save', {
        facility_id: facility.facility_id,
        units: facility.units_statistics,
      })
      // fetchData()
    } catch (e) {
    }
  }

  const handleSort = (columnName, isASC) => {
    if (columnName === sort) {
      setOrderby(isASC ? 'asc' : 'desc')
    } else {
      setSort(columnName)
      setOrderby('asc')
    }
  }

  const refreshData = () => {
    navigate('/loading?redirect=street-rates')
  }

  const handlePublishIndividual = (facility_id) => {
    setPublishIndividualFacilityId(facility_id);
    setModalIsOpen(true);
  }

  const confirmPublishIndividual = (facility_id) => {
    setIsProcessing(true);
    apiClient.post('/street_rates/submit-individual', { facility_id }).then(function (response) {
      setModalIsOpen(false);
      setPublishIndividualFacilityId(null);
      setTimeout(() => {
        setIsProcessing(false);
        if (window.confirm('Rates successfully published')) {
          setUpdatedFacilitiesCount(0);
          fetchData();
        }
      }, 300);
    }).catch(function (error) {
      console.log(error)
      setIsProcessing(false);
      setModalIsOpen(false);
      setPublishIndividualFacilityId(null);
      
      let message = '';
      let errorLogs = error?.response?.data?.errors?.errorLogs ?? [];

      if (errorLogs.length > 0) {
        message = errorLogs.map((log) => `<p class="mb-3">${log}</p>`).join('');
      }
      setErrorLog(message);
      setModalErrorIsOpen(true);
    })
  }

  async function getPortfolioSettings() {
    const portfolioDetail =
      (await portfolioService.getPortfolioDetail(portfolio_id)) || {};
    setPortfolioSettings(portfolioDetail)
    return portfolioDetail
  }

  const tableHeader = () => {
    return (
      <>
        <div className="p-4 flex justify-between">
          <SearchInput placeholder={'Search or filter...'} value={search} onChange={handleChange} />
          <div>
            <button
              className="px-8 mb-2 text-red-500 font-normal text-base flex items-center gap-4"
              onClick={refreshData}
            >
              <span className="material-symbols-rounded">
                refresh
              </span>
              Refresh Model
            </button>
            <button
              className="px-8 rounded rounded-lg bg-green-600 text-white"
              onClick={handlePublishNewRates}
            >
              Publish New Rates
            </button>
            <Modal
              isOpen={modalIsOpen}
            >
              <h2>Are you sure you want to publish the new street rates?</h2>
              <div class="action mt-4 text-center">
                <button
                  type="button"
                  className="border border-radius-lg border-default uppercase text-default text-sm mr-4"
                  onClick={closeModal}
                >
                  NO
                </button>
                <button
                  type="button"
                  className="border border-radius-lg border-primary uppercase text-primary text-sm"
                  onClick={() => {
                    if (publishIndividualFacilityId) {
                      confirmPublishIndividual(publishIndividualFacilityId)
                    } else {
                      confirmPublish()
                    }
                  }}
                >
                  YES
                </button>
              </div>
            </Modal>
            <Modal
              isOpen={isProcessing}
            >
              <div className='text-center' style={{width: '400px'}}>
                  <h2>Publishing new street rates...</h2>
                  <img src="/assets/images/publish-loading.svg"
                    className="w-14 mx-auto"
                    alt="loading"
                  />
                </div>
            </Modal>
            <Modal
              isOpen={modalErrorIsOpen}
            >
              <div className='text-center' style={{width: '600px'}}>
                  <h2>Error Alert</h2>
                  <div className="mt-5 text-red-500 text-center">There was an error publishing your rates. Please try again later or reach out to our Customer Success team for more information.</div>
                  <div className={`mt-2 text-center`} dangerouslySetInnerHTML={{__html: errorLog}}/>
                  <div className="action mt-4 text-center">
                    <button
                      type="button"
                      className="border border-radius-lg border-default uppercase text-default text-sm mr-4"
                      onClick={closeModalError}
                    >
                      Close
                    </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm text-slate-500 px-4">Last Updated: {latestPublishedDate}</div>
          <span className="text-sm text-slate-500 px-4">
            {updatedFacilitiesCount > 0
              ? `${updatedFacilitiesCount} ${updatedFacilitiesCount === 1 ? 'Facility' : 'Facilities'
              } Edited`
              : ''}
          </span>
        </div>
      </>
    )
  }

  const renderTable = () => {
    return (
      <>
        <div className="w-full rounded-2xl border-2 border-gray-200 px-6 pt-6 bg-white">
          {tableHeader()}
          {loading ? <div className="flex w-full h-4/5 items-center justify-center">Loading ...</div> :
            <StreetRatesTable
              data={data}
              sortColumn={sort}
              sortDirection={orderby}
              handleChange={handleFacilitiesChanged}
              handleUpdate={handleUpdate}
              onSortChanged={handleSort}
              onConfirmPublishIndividual={handlePublishIndividual}
              streetRateSettings={portfolioSettings?.street_rate_settings}
            />}
        </div>
        <Pagination
          totalPages={Math.ceil(pagination.total / PAGE_SIZE)}
          page={pagination.page}
          handlePagination={handlePagination}
        />
      </>
    )
  }

  return <Dashboard>{renderTable()}</Dashboard>
}

export default StreetRates
