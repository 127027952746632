import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useNavigate } from "react-router-dom";
import Dashboard from '../components/Dashboard'
import SearchInput from '../components/atom/SearchInput'
import ExistingCustomersTable from '../components/ExistingCustomersTable'
import Pagination from '../components/atom/Pagination'
import apiClient from '../utils/apiClient'
import Modal from '../components/atom/Modal'

const PAGE_SIZE = 10;

const ExistingCustomers = () => {
  const [currentSort, setCurrentSort] = useState({ facility_name: "up" });
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [debouncedTerm, setDebouncedTerm] = useState(search)
  const [updatedFacilities, setUpdateFacilities] = useState([])
  const [latestPublishedDate, setLatestPublishedDate] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorLog, setErrorLog] = useState('');
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  
  const navigate = useNavigate();

  const sort = (key, currSort, dataType) => {
    const sortedData = [...data].sort((a, b) => {
      if (key === "eligible_tenants") {
        if (currSort === "up") {
          return a.tenants.length - b.tenants.length;
        } else if (currSort === "down") {
          return b.tenants.length - a.tenants.length;
        }
      } else if (key === "market") {
        if (currSort === "up") {
          return `${a.city}, ${a.state}`.localeCompare(`${b.city}, ${b.state}`);
        } else if (currSort === "down") {
          return `${b.city}, ${b.state}`.localeCompare(`${a.city}, ${a.state}`);
        }
      } else if (dataType === "number") {
        const aValue = a[key] ?? 0;
        const bValue = b[key] ?? 0;
        if (currSort === "up") {
          return aValue - bValue;
        } else if (currSort === "down") {
          return bValue - aValue;
        }
      } else if (dataType === "string") {
        if (currSort === "up") {
          return a[key].localeCompare(b[key]);
        } else if (currSort === "down") {
          return b[key].localeCompare(a[key]);
        }
      }
      return 0;
    });
    setData(sortedData);
  };

  const toggleSort = (key, dataType) => {
    let currSort = currentSort[key] || "up-down";
    if (currSort === "up-down") {
      currSort = "up";
    } else if (currSort === "up") {
      currSort = "down";
    } else if (currSort === "down") {
      currSort = "up";
    }
    sort(key, currSort, dataType);
    setCurrentSort({ [key]: currSort });
  };

  // Summary Data
  const [sumTenants, setSumTenants] = useState() // TOTAL ELIGIBLE TENANTS
  const [sumAvrRateInc, setSumAvrRateInc] = useState() // AVERAGE RATE INCREASE %
  const [sumRevInc, setSumRevInc] = useState() // ESTIMATED REVENUE INCREASE %
  const [sumAvrMOP, setSumAvrMOP] = useState() // AVERGE MOVE-OUT PROBABILITY

  const processSummaryData = (facilities) => {
    let sum_tenants = 0, sum_avr_rate_inc = 0, sum_rev_inc = 0, sum_avr_mop = 0, fc_length = 0
    for (const fc of facilities) {
      if (!fc.tenants.length) continue
      fc_length++
      sum_tenants += fc.tenant_total
      sum_avr_rate_inc += fc.avr_rate_increase_percent
      sum_avr_mop += fc.avr_moveout_probability * 100.0
      for (const tnt of fc.tenants) {
        sum_rev_inc += (tnt.new_rate - tnt.current_rate) * 100.0 / tnt.current_rate
      }
    }

    setSumTenants(sum_tenants)
    setSumAvrRateInc((sum_avr_rate_inc / fc_length).toFixed(1))
    setSumRevInc((sum_rev_inc / sum_tenants).toFixed(1))
    setSumAvrMOP((sum_avr_mop / fc_length).toFixed(1))
  }

  const processData = (facilities) => {
    // default sort by facility name
    setData(facilities.sort((a, b) => a.facility_name.localeCompare(b.facility_name)));
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      const token = localStorage.getItem('token')

      let dataURL = `/api/ecri?`
      if (search) dataURL += `search=${search}&`
      dataURL += `status=enabled&`
      dataURL += `page=${currentPage}&`
      dataURL += `limit=${PAGE_SIZE}`
      const response = await fetch(dataURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const { result, pagination } = await response.json()
      processData(result)
      setPagination(pagination)
    } catch (error) {
      console.error('Error fetching data: ', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchSummaryData = async () => {
    try {
      setLoading(true)
      const token = localStorage.getItem('token')

      let dataURL = `/api/ecri/summary`
      const response = await fetch(dataURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const { result } = await response.json()
      processSummaryData(result)
    } catch (error) {
      console.error('Error fetching data: ', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(search)
      setCurrentPage(1)
    }, 500)

    return () => {
      clearTimeout(timerId)
    }
  }, [search])

  useEffect(() => {
    fetchSummaryData()
    fetchData()
  }, [debouncedTerm, currentPage])

  const handleChange = (e) => {
    const value = e.target.value
    setSearch(value)
  }

  const handlePagination = (page) => {
    setCurrentPage(page)
    setPagination(pagination => ({ ...pagination, page: page }))
  }

  const handleFacilitiesChanged = (facility_id) => {
    const facilities = [...updatedFacilities]
    if (!facilities.includes(facility_id)) facilities.push(facility_id)
    setUpdateFacilities(facilities)
  }

  const handlePublishNewRates = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const closeModalError = () => {
    setModalErrorIsOpen(false);
    setErrorLog('');
  }

  const confirmPublish = () => {
    setIsProcessing(true);
    apiClient.post('/ecri/publish').then(function (response) {
      setLatestPublishedDate(moment(new Date()).format('MM/DD/YYYY, hh:mm A'));
      refreshData();
      setCurrentPage(1)
      setModalIsOpen(false);
      setTimeout(() => {
        setIsProcessing(false);
      }, 300);
    }).catch(function (error) {
      console.log(error)
      setModalIsOpen(false);
      setIsProcessing(false);

      let message = '';
      let errorLogs = error?.response?.data?.errors || [];

      if (errorLogs.length > 0) {
        message = errorLogs.slice(0, 3).map((log) => `<p class="mb-2">${log}</p>`).join('');
      }

      if (errorLogs.length > 3) {
         message = message + "<p>...</p>"
      }
      setErrorLog(message);
      setModalErrorIsOpen(true);
    })
  }

  const refreshData = () => {
    navigate("/loading?redirect=existing-customer-rate-increases");
  };

  const columns = [
    {
      key: "facility_name",
      className: "text-left",
      dataType: "string",
      label: (
        <div className="flex items-center justify-between cursor-pointer">
          Facility
          {currentSort.facility_name && (
            <span className="material-symbols-rounded">
              {currentSort.facility_name === "up"
                ? "arrow_upward"
                : "arrow_downward"}
            </span>
          )}
        </div>
      ),
    },
    {
      key: "market",
      className: "text-left",
      dataType: "string",
      label: (
        <div className="flex items-center justify-between cursor-pointer">
          Market
          {currentSort.market && (
            <span className="material-symbols-rounded">
              {currentSort.market === "up" ? "arrow_upward" : "arrow_downward"}
            </span>
          )}
        </div>
      ),
    },
    {
      key: "eligible_tenants",
      className: "text-left",
      dataType: "number",
      label: (
        <div className="flex items-center justify-between cursor-pointer">
          Eligible
          <br />
          Tenants
          {currentSort.eligible_tenants && (
            <span className="material-symbols-rounded">
              {currentSort.eligible_tenants === "up"
                ? "arrow_upward"
                : "arrow_downward"}
            </span>
          )}
        </div>
      ),
    },
    {
      key: "avr_rate_increase_percent",
      className: "bg-neutral-500 rounded-bl-md text-left text-white",
      dataType: "number",
      label: (
        <div className="flex items-center justify-between cursor-pointer">
          Average
          <br />
          Rate
          <br />
          Increase %
          {currentSort.avr_rate_increase_percent && (
            <span className="material-symbols-rounded">
              {currentSort.avr_rate_increase_percent === "up"
                ? "arrow_upward"
                : "arrow_downward"}
            </span>
          )}
        </div>
      ),
    },
    {
      key: "avr_rate_increase_amount",
      className: "bg-neutral-500 text-left text-white",
      dataType: "number",
      label: (
        <div className="flex items-center justify-between cursor-pointer">
          Average
          <br />
          Rate
          <br />
          Change $
          {currentSort.avr_rate_increase_amount && (
            <span className="material-symbols-rounded">
              {currentSort.avr_rate_increase_amount === "up"
                ? "arrow_upward"
                : "arrow_downward"}
            </span>
          )}
        </div>
      ),
    },
    {
      key: "largest_rate_increase",
      className: "bg-neutral-500 text-left text-white",
      dataType: "number",
      label: (
        <div className="flex items-center justify-between cursor-pointer">
          Largest
          <br />
          Increase
          {currentSort.largest_rate_increase && (
            <span className="material-symbols-rounded">
              {currentSort.largest_rate_increase === "up"
                ? "arrow_upward"
                : "arrow_downward"}
            </span>
          )}
        </div>
      ),
    },
    {
      key: "avr_moveout_probability",
      className: "bg-neutral-500 text-left rounded-br-md text-white",
      dataType: "number",
      label: (
        <div className="flex items-center justify-between cursor-pointer">
          Average
          <br />
          Move-Out
          <br />
          Probability
          {currentSort.avr_moveout_probability && (
            <span className="material-symbols-rounded">
              {currentSort.avr_moveout_probability === "up"
                ? "arrow_upward"
                : "arrow_downward"}
            </span>
          )}
        </div>
      ),
    },
    {
      key: "actions",
      className: "",
      label: <div className="flex items-center">Actions</div>,
    },
  ];

  const renderTableHeader = () => {
    return (
      <thead>
        <tr className="no-padding">
          <th colSpan={3}>
            <SearchInput
              placeholder={"Search or filter..."}
              value={search}
              onChange={handleChange}
            />
          </th>
          <th className="text-gray-600">
            TOTAL
            <br />
            ELIGIBLE
            <br />
            TENANTS
          </th>
          <th className="text-gray-600">
            AVERAGE
            <br />
            RATE
            <br />
            INCREASE %
          </th>
          <th className="text-gray-600">
            ESTIMATED
            <br />
            REVENUE
            <br />
            INCREASE %
          </th>
          <th className="text-gray-600">
            AVERAGE
            <br />
            MOVE-OUT
            <br />
            PROBABILITY
          </th>
          <th align="right">
            <div>
              <button
                className="px-8 mb-2 text-red-500 font-normal text-base flex items-center gap-4"
                onClick={refreshData}
              >
                <span className="material-symbols-rounded">refresh</span>
                Refresh Model
              </button>
            </div>
            <div>
              <button
                className="px-8 rounded rounded-lg bg-green-600 text-white font-normal text-base"
                onClick={handlePublishNewRates}
              >
                Publish New Rates
              </button>
              <Modal isOpen={modalIsOpen}>
                <h2>Are you sure you want to publish the new rates?</h2>
                <div class="action mt-4 text-center">
                  <button
                    type="button"
                    className="border border-radius-lg border-default uppercase text-default text-sm mr-4"
                    onClick={closeModal}
                  >
                    NO
                  </button>
                  <button
                    type="button"
                    className="border border-radius-lg border-primary uppercase text-primary text-sm"
                    onClick={confirmPublish}
                  >
                    YES
                  </button>
                </div>
              </Modal>
              <Modal
                isOpen={isProcessing}
              >
                <div className='text-center' style={{ width: '400px' }}>
                  <h2>Publishing rate increases...</h2>
                  <img src="/assets/images/publish-loading.svg"
                    className="w-14 mx-auto"
                    alt="loading"
                  />
                </div>
              </Modal>
              <Modal
              isOpen={modalErrorIsOpen}
            >
              <div className='text-center' style={{width: '600px'}}>
                  <h2>Error Alert</h2>
                  <div className="mt-5 text-red-500 text-center">There was an error publishing your rates. Please try again later or reach out to our Customer Success team for more information.</div>
                  <div className={`mt-2 text-center`} dangerouslySetInnerHTML={{__html: errorLog}}/>
                  <div className="action mt-4 text-center">
                    <button
                      type="button"
                      className="border border-radius-lg border-default uppercase text-default text-sm mr-4"
                      onClick={closeModalError}
                    >
                      Close
                    </button>
                </div>
              </div>
            </Modal>
            </div>
          </th>
        </tr>
        <tr className="no-padding">
          <th colSpan={3} align="left" className="text-sm text-slate-500 px-4 font-normal">
            Last Updated: {latestPublishedDate}
          </th>
          <th className="font-normal">{sumTenants}</th>
          <th className="font-normal">{sumAvrRateInc}%</th>
          <th className="font-normal">
            <span className="px-6 py-2 bg-green-200 rounded rounded-md">{sumRevInc}%</span>
          </th>
          <th className="font-normal">
            <span className="px-6 py-2 bg-green-200 rounded rounded-md">{sumAvrMOP}%</span>
          </th>
          <th align="right">
            <span className="py-2 font-normal text-sm text-slate-500 px-4">
              {updatedFacilities.length
                ? `${updatedFacilities.length} ${updatedFacilities.length === 1 ? 'Facility' : 'Facilities'
                } Edited`
                : ''}
            </span>
          </th>
        </tr>
        <tr className="h-4"></tr>
        <tr>
          <th colSpan={3}></th>
          <th
            colSpan={4}
            className="bg-neutral-500 rounded-t-md text-white !py-1"
          >
            New
          </th>
          <th></th>
        </tr>
        <tr className="uppercase text-gray-600 border-t-2 border-neutral-300">
          {columns.map((column) => (
            <th
              key={column.key}
              className={column.className}
              onClick={
                column.dataType
                  ? () => toggleSort(column.key, column.dataType)
                  : null
              }
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const renderTable = () => {
    if (loading) {
      return (
        <div className="flex w-full h-4/5 items-center justify-center">
          Loading ...
        </div>
      );
    }
    return (
      <>
        <div className="w-full rounded-2xl border-2 border-gray-200 px-6 pt-6 bg-white">
          <ExistingCustomersTable
            rows={data}
            handleChange={handleFacilitiesChanged}
            renderTableHeader={renderTableHeader}
          />
        </div>
        <Pagination
          totalPages={Math.ceil(pagination.total / PAGE_SIZE)}
          page={pagination.page}
          handlePagination={handlePagination}
        />
      </>
    );
  };

  return <Dashboard>{renderTable()}</Dashboard>;
};

export default ExistingCustomers;
